export default {
  "Modern": "Moderne",
  "eCommerce": "commerce électronique",
  "Contact": "a lua legatura",
  "Blog": "Blog",
  "Posts": "Des postes",
  "Detail": "Détail",
  "E-Commerce": "Commerce électronique",
  "Shop": "Boutique",
  "List": "Liste",
  "Checkout": "Vérifier",
  "Chats": "Chattes",
  "User Profile": "Profil des utilisateurs",
  "Notes": "Remarques",
  "Calendar": "Calendrier",
  "Profile": "Profil",
  "Followers": "Suiveuses",
  "Friends": "Amies",
  "Gallery": "Galerie",
  "Email": "E-mail",
  "Pricing": "Tarification",
  "Account Setting": "Paramètre du compte",
  "FAQ": "FAQ",
  "Widget": "Widget",
  "Landing Page": "Page de destination",
  "Form Elements": "Éléments de formulaire",
  "Autocomplete": "Saisie automatique",
  "Combobox": "Boîte combo",
  "Button": "Bouton",
  "Checkbox": "Case à cocher",
  "Custom Inputs": "Entrées personnalisées",
  "File Inputs": "Entrées de fichier",
  "Radio": "Radio",
  "Date Time": "Date Heure",
  "Select": "Sélectionner",
  "Slider": "Glissière",
  "Switch": "Changer",
  "Form Layout": "Disposition du formulaire",
  "Form Horizontal": "Forme horizontale",
  "Form Vertical": "Forme verticale",
  "Form Custom": "Formulaire personnalisé",
  "Form Validation": "Validation du formulaire",
  "Editor": "Éditrice",
  "Basic Table": "Tableau de base",
  "Dark Table": "Tableau noir",
  "Density Table": "Tableau de densité",
  "Fixed Header Table": "Table d'en-tête fixe",
  "Height Table": "Tableau des hauteurs",
  "UI Components": "Composants de l'interface utilisateur",
  "Alert": "Alerte",
  "Accordion": "Accordéon",
  "Avatar": "Avatar",
  "Chip": "Ébrécher",
  "Dialog": "Dialogue",
  "Menus": "Menus",
  "Rating": "Notation",
  "Tabs": "Filele",
  "Tooltip": "Info-bulle",
  "Typography": "Typographie",
  "Line": "Doubler",
  "Gredient": "Ils entreront",
  "Area": "Zone",
  "Candlestick": "Chandelier",
  "Column": "Colonne",
  "Doughnut & Pie": "Donut et Tarte",
  "Radialbar & Radar": "Barre radiale et radar",
  "Login": "Connexion",
  "Side Login": "Connexion latérale",
  "Boxed Login": "Connexion en boîte",
  "Register": "Enregistrer",
  "Side Register": "Registre latéral",
  "Boxed Register": "Registre en boîte",
  "Forgot Password": "Mot de passe oublié",
  "Side Forgot Password": "Mot de passe oublié",
  "Boxed Forgot Password": "Mot de passe oublié",
  "Two Steps": "Deux étapes",
  "Side Two Steps": "Côté deux étapes",
  "Boxed Two Steps": "Deux étapes en boîte",
  "Error": "Erreur",
  "Maintenance": "Entretien",
  "Menu Level": "Niveau menus",
  "Level 1": "Niveau 1",
  "Level 2": "Niveau 2",
  "Level 3": "Niveau 3",
  "Disabled": "Désactivé",
  "Sub Caption": "Sous-titre",
  "Outlined": "Décrite",
  "External Link": "lien externe",
  "Editable Table":"Tableau modifiable",
  '10': '10',
  '25': '25',
  '50': '50',
  '100': '100',
  
  "shipping_method_1": "Retrait à la cave",
  "shipping_method_2": "GLS",
  "shipping_method_3": "Stuart",
  "shipping_method_null": "Non défini",
  long: {
    year: 'numeric',
    // month: 'long',
    day: 'numeric',
    // weekday: 'long',
    hour: 'numeric',
    minute: 'numeric'
  },
  $vuetify: {
    badge: 'Badge',
    open: 'Open',
    close: 'Fermer',
    dataIterator: {
      noResultsText: 'Aucun enregistrement correspondant trouvé',
      loadingText: `Chargement de l'élément...`,
    },
    dataTable: {
      itemsPerPageText: 'Lignes par page :',
      ariaLabel: {
        sortDescending: 'Tri décroissant.',
        sortAscending: 'Tri croissant.',
        sortNone: 'Non trié.',
        activateNone: 'Activer pour supprimer le tri.',
        activateDescending: 'Activer pour trier par ordre décroissant.',
        activateAscending: 'Activer pour trier par ordre croissant.',
      },
      sortBy: 'Trier par',
    },
    dataFooter: {
      itemsPerPageText: 'Élements par page :',
      itemsPerPageAll: 'Tous',
      nextPage: 'Page suivante',
      prevPage: 'Page précédente',
      firstPage: 'Première page',
      lastPage: 'Dernière page',
      pageText: '{0}-{1} de {2}',
    },
    dateRangeInput: {
      divider: 'to',
    },
    datePicker: {
      ok: 'OK',
      cancel: 'Cancel',
      range: {
        title: 'Select dates',
        header: 'Enter dates',
      },
      title: 'Select date',
      header: 'Enter date',
      input: {
        placeholder: 'Enter date',
      },
    },
    noDataText: 'Aucune donnée disponible',
    carousel: {
      prev: 'Visuel précédent',
      next: 'Visuel suivant',
      ariaLabel: {
        delimiter: 'Diapositive {0} de {1}',
      },
    },
    calendar: {
      moreEvents: '{0} de plus',
    },
    input: {
      clear: 'Clear {0}',
      prependAction: '{0} prepended action',
      appendAction: '{0} appended action',
      otp: 'Please enter OTP character {0}',
    },
    fileInput: {
      counter: '{0} fichier(s)',
      counterSize: '{0} fichier(s) ({1} au total)',
    },
    timePicker: {
      am: 'AM',
      pm: 'PM',
    },
    pagination: {
      ariaLabel: {
        root: 'Navigation de pagination',
        next: 'Page suivante',
        previous: 'Page précédente',
        page: 'Aller à la page {0}',
        currentPage: 'Page actuelle, Page {0}',
        first: 'First page',
        last: 'Last page',
      },
    },
    stepper: {
      next: 'Next',
      prev: 'Previous',
    },
    rating: {
      ariaLabel: {
        item: 'Note de {0} sur {1}',
      },
    },
    loading: 'Loading...',
    infiniteScroll: {
      loadMore: 'Load more',
      empty: 'No more',
    },
  },
  stores:{
    producers: "Vignerons",
    products: "Produits",
    orders: "Commandes",
    users: "Utilisateurs",
    messages: "Messages",
    vouchers: "Bons Cadeaux",
    settings: "Paramètres",
    options:{
      archived: "Archivés",
      completed: "Complétees",
      carts: "Paniers",
      add:{
        producers: "Ajouter un producteur"
      }
    }
  },
  vouchers:{
    files:{
      back: "Dos",
      front: "Face",
      back_page: "Page Dos (avec guides)",
      front_page: "Page Face (avec guides)",
      printable: "Page à imprimer"
    },
  },
  figures:{
      total_month: "Commandé ce mois",
      orders_count: "Nbre  Commandes",
      orders_products_count: "Produits Commandés",
      average_product_euros: "Moyenne par Produit",
      products_all_products: "Catalogue",
      products_online: "Produits en ligne",
      products_arrival: "Nouveautés",
      products_out_of_stock: "Produits en rupture",
      activeUsers: "Utilisateurs actifs",
      newUsers: "Nouveaux utilisateurs",
      screenPageViews: "Pages vues",
      sessions: "Sessions",
      totalUsers: "Total utilisateurs",
  },
  products:{
    fields:{
      varieties: "Cépages",
      type: "Type de produit",
      subcategory: "Couleur",
      format: "Format",
      region: "Région",
    },
    versions:{
      change_update: " passe de",
      change_create: " est créé",
    }
  },
  stuartJob:{
    scheduled: "Planifiée",
    in_progress: "En cours",
    finished: "Terminée",
    searching: "Recherche de coursier",
  },
  orders:{
    versions:{
      change_update: " passe de",
      change_create: " est créé",
    },
    states:{
      shopping_cart_down: "Panier",
      shipping_confirmed_down: "Expédition confirmée",
      paid_down: "Payée",
      paid_up: "Payée",
      invoice_up: "Valider la facture",
      invoice_down: "Facture validée",
      sent_down: "Facture envoyée",
      sent_up: "Envoyer la facture",
      label_up: "Prêt pour Enlèvement ",
      label_down: "Étiquette imprimée",
      shipped_up: "Confirmer l'expédition",
      shipped_down: "Expédiée",
      completed_down: "Complétée",
      completed_up: "Livrée",
      delivered_down: "Complétée",
      refunded_down: "Remboursée",
      cancelled_down: "Annulée",
      ready_down: "Enlevée",
      ready_up: "Prêt pour enlèvement",
      address_confirmed_down: "Adresses confirmées",
      booking_stuart_down: "Réservation Stuart",
      invoice_sent_down: "Facture envoyée",
      invoice_reviewed_down: "Facture revue",

    },
  },
  pagesTitles:{
    producers: "Vignerons",
    dashboard: "Tableau de bord",
    stats: "Statistiques",
    categories: "Catégories",
    products: "Produits",
    orders: "Commandes",
    users: "Utilisateurs",
    messages: "Messages",
    vouchers: "Bons Cadeaux",
    settings: "Paramètres",
    stories: "Contenus",
    "shipping-config": "Configuration Livraison",   
    "shipping": "Tarifs & Livraison",
    "shipping-gls": "GLS",
    "shipping-stuart": "Stuart",
    "monitoring-redis": "Redis",
    "monitoring-perfs": "Performances",
    "products-id": "{name}",
    "orders-id": "{name}",
    "producers-id": "{name}",
    "vouchers-id": "Chèque Cadeau",

    options:{
      archived: "Archivés",
      completed: "Complétees",
      carts: "Paniers",
      add:{
        producers: "Ajouter un producteur"
      }
    }
  },
}