import fr from "@/locales/fr-FR.js";
export default {
  datetimeFormats: {
    'fr-FR': {
      short: {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        // month: 'long',
        day: 'numeric',
        // weekday: 'long',
        hour: 'numeric',
        minute: 'numeric'
      }
    },
  },
  legacy: false,
  locale: 'fr-FR',
  fallbackWarn: false,
  messages: {
    'fr-FR': fr,
  }
}